import axios from 'axios'; import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

export default function useRouterQuery() {
    const { search } = useLocation();

    const query = useMemo(() => {
        return new URLSearchParams(search);
    }, [search]);
    return query;
}

var baseUrl = `${process.env.REACT_APP_API_URL}`;

const instance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Allow-Headers': '*',
    }
});

export const getOrderSheet = (id) => {
    return instance.get(`/purchase/order/${id}/detail`)
        .then((response) => {
            console.log(response.data.res)
            return response.data.res
        })
        .catch((error) => {
            console.log(error.response.data)
        })
}