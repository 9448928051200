import React from 'react';
import * as ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './index.css'
import CopyAccount from './pages/copyAccount'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path : '/',
    element : <div>Knowd Service Main</div>
  },
  {
    path : '/copyAccount',
    element : <CopyAccount/>
  }
])

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <RouterProvider router={router}/>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
