import React, { useEffect, useState } from 'react';
import useRouterQuery, { getOrderSheet } from '../apis';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';

const accountInfo = {
    bank : "국민은행",
    accountNumber : "356237-04-006572",
    accountHolder : "주식회사 에스아카이브"
}

const CopyAccount = () => {
    const navigate = useNavigate();
    const routerQuery = useRouterQuery();
    const id = routerQuery.get('id')
    const { data: orderSheet, isLoading, isError } = useQuery(
        ['order-sheet', id],
        () => getOrderSheet(id),
    );
    const [_accountInfo,setAccountInfo] = useState(accountInfo);

    const formatKoCurrency = (number) => {
        return number.toLocaleString('ko-KR') + '원'
    }

    const accountInfoToText = () => {
        return `${_accountInfo.bank} ${_accountInfo.accountHolder} ${_accountInfo.accountNumber} ${formatKoCurrency(_accountInfo.amount)}`
    }

    const writeAccountToClipboard = async () => {
        console.log(navigator.clipboard)
        var now = Date.now()
        console.log(now)
        try {
            await navigator.clipboard?.writeText(accountInfoToText());
        } catch (error) {
            console.error('Failed to copy text to clipboard:', error);
        }
    };

    useEffect(() => {
        if ((orderSheet && _accountInfo.amount === undefined) 
        || (orderSheet && (_accountInfo.amount !== orderSheet.pay_info.pay_amount))) {
            setAccountInfo({
                ..._accountInfo,
                amount : orderSheet.pay_info.pay_amount
            })
        }
        else if (_accountInfo.amount){
            writeAccountToClipboard();
        }
        else if (isError || id === undefined){
            navigate('../')
        }
    }, [orderSheet,id,_accountInfo.amount]);

    return (
        <div style={{
            width: '100vw',
            height: '100vh',
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }}>
            {isLoading && <div>Loading...</div>}
            {isError && <div>Error...</div>}
            {orderSheet && (
                <div style={{
                    display:"flex", flexDirection:'column',
                    gap:16
                }}>
                    <div>주문서 번호 : {orderSheet.id}</div>
                    <div>은행 : {_accountInfo.bank}</div>
                    <div>예금주명 : {_accountInfo.accountHolder}</div>
                    <div>계좌번호 : {_accountInfo.accountNumber}</div>
                    <div>결제금액 : {_accountInfo.amount ? formatKoCurrency(_accountInfo.amount) : ''}</div>
                    <p style={{
                        color: 'orangered'
                    }}>자동으로 입금정보가 복사되지 않았다면 아래 버튼을 클릭해주세요.
                        <div style={{
                            margin : "8px 0"
                        }}>
                            <button style={{
                                padding: '8px'
                            }} onClick={writeAccountToClipboard}>입금정보 복사하기</button>
                        </div>
                    </p>
                </div>
            )}
        </div>
    );
};

export default CopyAccount;